import { Col, Row } from 'antd';
import React from 'react';
import HomePageForm from './HomePageForm';

class HomePage extends React.Component {

    render() {
        return (
            <div>

                <Row gutter={{ xs: 8, sm: 16, md: 24 }} className="App-header">
                    <Col offset={2} span={20} >
                        <img src="https://api.ceylovillas.com:7067/public/imgs/1722587300656.png" className="App-logo" alt="Logo" />
                        <h1 style={{ color: "white" }}>Visitor Management App</h1>
                        <p> Keep Calm & Stay Safe</p>
                    </Col>
                </Row>
                <HomePageForm />

            </div>
        )
    }

}

export default HomePage;